import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";

import "../styles/pages/_lp.scss";
import heroImg from "../images/lp/TSD_International_Hero.png";
import sound1Image from "../images/lp/TSD_International_Sound_1.png";
import sound2Image from "../images/lp/TSD_International_Sound_2.png";
import sound3Image from "../images/lp/TSD_International_Sound_3.png";
import sound2 from "../audio/MarketExpansion_LocalisationAudio.mp3";
import sound1 from "../audio/MarketExpansion_MarketFitAudio.mp3";
import sound3 from "../audio/MarketExpansion_SubregionsAudio.mp3";

import icon_growth from "../images/lp/TSD_Industry-Growth.svg";
import icon_launch from "../images/lp/TSD_Industry-Launch.svg";
import icon_management from "../images/lp/TSD_Industry-Management.svg";
import bike from "../images/lp/TSD_Dance_Services_Start.png";

import sample2 from "../images/lp/TSD_Startups_Noble_Fig.png";
import sample3 from "../images/lp/TSD_Restaurant_Bag_Skipper_Otto.png";
import sample4 from "../images/lp/TSD_Restaurant_Puccini.png";
import sample5 from "../images/lp/TSD_International_Firefly.png";
import sample6 from "../images/lp/TSD_Tag.png";
import sample7 from "../images/lp/TSD_Ecommerce_Glory.png";
import sample8 from "../images/lp/TSD_Unified.png";
import sample9 from "../images/lp/TSD_Restaurant_MWL.png";

import sample_bg1 from "../images/lp/TSD_Industry-Graphic_2.svg";
import sample_bg2 from "../images/lp/TSD_Industry-Graphic_1.svg";
import sample_bg3 from "../images/lp/TSD_Industry_Graphic_3.svg";

var settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "700px",
  slidesToShow: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "500px",
      },
    },
    {
      breakpoint: 2000,
      settings: {
        centerPadding: "300px",
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 821,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
      },
    },
  ],
};
//Department Tabs
const openDepartment = (evt, cityName) => {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  console.log(tabcontent, "kkkk");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "flex";
  evt.currentTarget.className += " active";
};
const InternationalLp = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="North American Marketing Expansion"
      description="Looking to expand into the North American Market? From localizing your packaging to running country-specific advertising, your brand and product need to capture the hearts and minds of the North American consumer."
    />

    <section className="ms-lp-hero international-lp-hero">
      <img src={heroImg} className="hero-img" />
      <div className="container">
        <div className="content-wrapper">
          <h2>Looking to EXPAND into the North American Market?</h2>
          <p>
            From Market Research to localizing your packaging, to running
            country and demographic specific advertising, your brand and product
            need to capture the hearts and minds of the North American consumer
            and we can help you identify market opportunities and expand
            sucessfully.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link
                to="#sampling-our-market-expansion"
                className="lp-btn red-btn"
              >
                UNLOCK North America
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
    <section className="ms-lp-slider">
      <Slider {...settings} className="symptoms-slider">
        <div className="give-padding">
          <div className="single-slide international-slide1">
            <div className="slide-content">
              <h3>Market Research</h3>
              <p>
                Determining where your product or service fits in North America
                is a really important first step as our behaviour, cultural
                habits and rituals are very different than from your side of the
                pond and we can help you identify the unique niches and open
                fields in which you can build your North American foothold.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide international-slide2">
            <div className="slide-content">
              <h3>Localising Packaging</h3>
              <p>
                From understanding language requirements, nutritional fact table
                standards (the original NFT) and how your shelf peers and
                competitors are presenting their offerings will help us find
                unique opportunities to differentiate your brand and product
                experience - while staying compliant to FDA and Health Canada
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide international-slide3">
            <div className="slide-content">
              <h3>Direct to Consumer</h3>
              <p>
                While almost all of the same tools and communication channels
                will be used in North America, our citizens are much less mobile
                first than they are in Europe, so ensuring that the right
                advertising mediums, channels and campaign structures are used
                is key to building a healthy ROI on your digital marketing
                campaigns.
              </p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="container">
        <h2 className="text-center pt-5">
          Let’s make this whole thing less overwhelming.
        </h2>
        <p className="text-center spacer-1">
          It won’t be easy, and it probably won’t be as fast as we would all
          like, but with our Market Expansion Formula (MEF), we can make sure
          that no time, resources or emotional energy is wasted in getting the
          West open for business.
        </p>
        <p className="btn-cta-wrapper text-center mb-5">
          <strong>
            <Link to="#head-west" className="lp-btn white-btn">
              Ready to head West?
            </Link>
          </strong>
        </p>
      </div>
    </section>
    <section className="ms-lp-action">
      <div className="container">
        <div>
          <h2 className="max-1000">
            Some of our insights into Market Expansion:
          </h2>
          <p>…scale your brand foot print into North America.</p>
        </div>
        <div className="audio-container">
          <div className="single-audio">
            <img src={sound1Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>How to find your customer in North America.</strong>
                </figcaption>
                <p>
                  Leveraging market research, social listening and competitive
                  intel to help.
                </p>
              </div>
              <audio controls src={sound1}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound2Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Avoiding Costly Packaging Mistakes</strong>
                </figcaption>
                <p>
                  Localising your packaging goes beyond legal requirements, it’s
                  about visual appeal to a different consumer.
                </p>
              </div>
              <audio controls src={sound2}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound3Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>
                    Where do North American Consumers Spend Their Time?
                  </strong>
                </figcaption>
                <p>
                  A less mobile society, but one that consumes and endless
                  amount of media.
                </p>
              </div>
              <audio controls src={sound3}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section className="industry-sample-work international-sample-work mt-5">
      <div className="container">
        <h2>Sampling of Our Creations</h2>
        <p>
          Now, do not expect the same results from this small collection of
          online creations of ours. There is so much that goes into the final
          result that depends on your brand, visual language, content and of
          course – your budget. So what we have tried to showcase is a full
          spectrum of results, based on all of those factors.
        </p>
      </div>
      <div className="sample-container international">
        <div className="image-wrapper">
          <img src={sample5} className="sample5" />
          <img src={sample4} className="sample4" />
          <img src={sample8} className="sample6" />
          <img src={sample2} className="sample1" />
          <img src={sample9} className="sample9" />
          <img src={sample6} className="sample2" />
          <img src={sample7} className="sample7 mb-4" />
          <img src={sample3} className="sample3" />
        </div>
        <img src={sample_bg1} className="sample-bg1" />
        <img src={sample_bg2} className="sample-bg2" />
        <img src={sample_bg3} className="sample-bg3" />
      </div>
    </section>
    <section
      id="sampling-our-market-expansion"
      className="industry-department international-tabs"
    >
      <div className="container">
        <h2>A sampling of our Market Expansion Menu</h2>
        <div className="tab-header">
          <button
            className="tablinks active"
            onClick={(e) => openDepartment(e, "launch_department")}
          >
            <img src={icon_launch} />
            The Launch <br></br>Department
          </button>
          <button
            className="tablinks"
            onClick={(e) => openDepartment(e, "growth_department")}
          >
            <img src={icon_growth} />
            The Growth <br></br>Department
          </button>
          <button
            className="tablinks"
            onClick={(e) => openDepartment(e, "management_department")}
          >
            <img src={icon_management} />
            The Management <br></br>Department
          </button>
        </div>
        <div className="tab-body">
          <div
            id="launch_department"
            class="tabcontent"
            style={{ display: "flex" }}
          >
            <div className="department-item">Simple Websites</div>
            <div className="department-item">Basic Brand Development</div>
            <div className="department-item">SEO Services</div>
          </div>

          <div id="growth_department" class="tabcontent">
            <div className="department-item">Growth Marketing</div>
            <div className="department-item">Website SEO</div>
            <div className="department-item">Digital Advertising</div>
          </div>

          <div id="management_department" class="tabcontent">
            <div className="department-item">Martech Management</div>
            <div className="department-item">Content Planning & Strategy</div>
            <div className="department-item">Marketing Consulting</div>
          </div>
        </div>
      </div>
    </section>
    <section id="head-west" className="da-lp-hero pre-paid-design-footer">
      <img src={bike} />
      <div className="container">
        <div className="content-wrapper">
          <h2>Ready to head West?</h2>
          <p>
            Click the button below to launch a chat with one of our Project
            Managers (aka experts of all) who can help you decide what services
            are the right fit for your market expansion.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <a className="lp-btn white-btn-blue-text">Let’s Go!</a>
            </strong>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default InternationalLp;
